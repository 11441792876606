import React, { useState } from 'react'
import { sendPostRequest } from './api';
import { clientkey } from './clientkey'
import { Formik } from 'formik';
import { validationSchema } from './validation'
import { Form, Button } from 'react-bootstrap'
import { ConfirmItem, FormItem, FormSelectItem, FormCheckbox } from './FormComponents'
import { statelist } from './statelist'
import { idtypelist, sexlist, countylist, rellist } from './enumdata'

const headernote = "A copy of a birth record may only be issued to the person named on the Certificate; a parent or court-appointed guardian; a representative with a notarized letter signed by the person named on the Certificate or a parent or guardian granting permission to obtain a Certificate; a surviving spouse, an individual with a court order directing that the Certificate be issued; or an individual permitted to obtain a certificate under Md. Code Ann., Family Law Title 5, Subtitles 3A or 4B relating to adoptions."
// const headernote2 = "Birth Certificates purchased online must be picked up within 7 days. On or after the 8th day, the birth certificate will be mailed to the address listed on the Government issued photo ID. If this address is not current, two documents with your current mailing address must be submitted. These documents must include two of the following: Utility bill, car registration form, pay stub, bank statement, copy of income tax return/W-2 form, letter from a government agency requesting a vital record, or lease/rental agreement."
const idnote = "The individual requesting the record should submit a legible copy of his/her VALID GOVERNMENT-ISSUED PHOTO ID with completed application. (Examples: State issued driver’s license or non-driver photo ID with requestor’s current address; passport). If you do not have a Government-issued photo ID, read the following statement: I declare that I do not have a government-issued photo ID and that I am presenting the attached two documents that include my name and current address as proof of identification. (Note: These documents must include two of the following: Utility bill, car registration form, pay stub, bank statement, copy of income tax return/W-2 form, letter from a government agency requesting a vital record, or lease/rental agreement. Please submit photocopies since these documents will not be returned to you. If you do not have a Government-issued photo ID, the certificate(s) will be mailed to the address listed on the documents that you present.)"
const feenote = "There is no fee for: (a) A copy of a certificate of a current or former armed forces member that is requested by the member; or (b) A copy of a certificate of a current or former armed forces member or of a surviving spouse or child of the member, if the copy will be used in connection with a claim for a dependent or beneficiary of the member. Proof of service in the armed forces must be provided."
const othernamenote = "If name has changed since birth due to adoption, court order, or any reason other than marriage, please list new name here:"

const MainContent = (props) => {
  // const [ list, setList ] = useState([]);
  const [ state, setState ] = useState('init');
  const [ confirmationID, setConfirmationID ] = useState('');
  const [ filedata, setFiledata ] = useState({ idfile: '', verifile1: '', verifile2: '', arelfile: '' });

  const submitData = (values) => {
    setState('pending');

    const cexpmC = values.cexpm ? values.cexpm : "";
    const cexpm = cexpmC < 10 ? '0' + cexpmC.toString() : cexpmC.toString();
    const cexpyC = values.cexpy ? values.cexpy : "";
    const cexpy = cexpyC > 2000 ? cexpyC.toString().substring(2) : cexpyC.toString();
    const aphone = values.aphone.replace(' ','').replace('(','').replace(')','').replace('-','');

    const cdata = {
      CardNumber: values.cnumb,
      CCV: values.csec,
      NameOnCard: values.chname,
      CreditCardBillingZipCode: values.badifferent ? values.bzip : values.azip,
      CardExpiresMonth: cexpm,
      CardExpiresYear: cexpy
    }

    const cdatastring = JSON.stringify(cdata);

    sendPostRequest('submit', {
      'clientkey': clientkey,
      'agency': props.agency,
      'data': JSON.stringify({
        aname: values.aname,
        arel: values.arel === "Other" ? values.arelo : values.arel,
        aadd1: values.aadd1,
        aadd2: values.aadd2,
        acity: values.acity,
        astate: values.astate,
        azip: values.azip,
        aphone: aphone,
        aemail: values.aemail,
        idtype: values.idtype,
        name: values.name,
        oname: values.oname,
        bdate: new Date(values.bdate).toISOString(),
        age: values.age,
        bsex: values.bsex,
        bplace: values.bplace,
        hospital: values.hospital,
        mmname: values.mmname,
        faname: values.fanameunknown ? "UNKNOWN" : values.faname,
        ncerts: values.ncerts,
        badd1: values.badifferent ? values.badd1 : values.aadd1,
        badd2: values.badifferent ? values.badd2 : values.aadd2,
        bcity: values.badifferent ? values.bcity : values.acity,
        bstate: values.badifferent ? values.bstate : values.astate,
        bzip: values.badifferent ? values.bzip : values.azip,
        cdata: cdatastring,
        idfile: filedata.idfile,
        verifile1: filedata.verifile1,
        verifile2: filedata.verifile2,
        arelfile: filedata.arelfile,
        charge: props.cost*parseInt(values.ncerts ? values.ncerts : 0),
        error: ''
      })
    }).then((data) => {
      // console.dir(data.id);
      setState('confirm');
      setConfirmationID(data.id);
    }).catch((err) => {
      // console.log(err);
      setState('fail');
    })
  }

  const submitBug = (values) => {
    if (values.aname === '') {
      setState('fail');
    } else {
      setState('pending');

      const cexpmC = values.cexpm ? values.cexpm : "";
      const cexpm = cexpmC < 10 ? '0' + cexpmC.toString() : cexpmC.toString();
      const cexpyC = values.cexpy ? values.cexpy : "";
      const cexpy = cexpyC > 2000 ? cexpyC.toString().substring(2) : cexpyC.toString();
      const aphone = values.aphone.replace(' ','').replace('(','').replace(')','').replace('-','');

      // console.log(cexpm);
      // console.log(cexpy);
      // console.log(aphone);

      const cdata = {
        CardNumber: values.cnumb,
        CCV: values.csec,
        NameOnCard: values.chname,
        CreditCardBillingZipCode: values.badifferent ? values.bzip : values.azip,
        CardExpiresMonth: cexpm,
        CardExpiresYear: cexpy
      }
    
      const cdatastring = JSON.stringify(cdata);
  
      const ecode = '(BUG REPORT ' + (values.validation ? '1' : '0') + (values.esig !== '' ? '1' : '0') + ')';
        
      // console.log(cexpy);
  
      sendPostRequest('submit', {
        'clientkey': clientkey,
        'agency': props.agency,
        'data': JSON.stringify({
          aname: values.aname,
          arel: values.arel === "Other" ? values.arelo : values.arel,
          aadd1: values.aadd1,
          aadd2: values.aadd2,
          acity: values.acity,
          astate: values.astate,
          azip: values.azip,
          aphone: aphone,
          aemail: values.aemail,
          idtype: values.idtype === 'DEFAULT' ? -1 : values.idtype,
          name: values.name,
          oname: values.oname,
          bdate: new Date(values.bdate !== '' ? values.bdate : '1/1/2030').toISOString(),
          age: values.age,
          bsex: values.bsex === 'DEFAULT' ? -1 : values.bsex,
          bplace: values.bplace === 'DEFAULT' ? -1 : values.bplace,
          hospital: values.hospital,
          mmname: values.mmname,
          faname: values.fanameunknown ? "UNKNOWN" : values.faname,
          ncerts: values.ncerts,
          badd1: values.badifferent ? values.badd1 : values.aadd1,
          badd2: values.badifferent ? values.badd2 : values.aadd2,
          bcity: values.badifferent ? values.bcity : values.acity,
          bstate: values.badifferent ? values.bstate : values.astate,
          bzip: values.badifferent ? values.bzip : values.azip,
          cdata: cdatastring,
          idfile: filedata.idfile,
          verifile1: filedata.verifile1,
          verifile2: filedata.verifile2,
          arelfile: filedata.arelfile,
          charge: props.cost*parseInt(values.ncerts ? values.ncerts : 0),
          error: ecode
        })
      }).then((data) => {
        // console.dir(data.id);
        setState('bug-confirm');
        setConfirmationID(data.id);
      }).catch((err) => {
        // console.log(err);
        setState('fail');
      })
    }
  }

  const ImportFromFileBodyComponent = (props) => {
    let fileReader;

    const handleFileRead = (e) => {
      const content = fileReader.result;
      // console.log(content);
      setFiledata({
        ...filedata,
        [props.name]: content
      });
    };
  
    const handleFileChosen = (file) => {
      fileReader = new FileReader();
      fileReader.onloadend = handleFileRead;
      fileReader.readAsDataURL(file);
    };

    return <div>
      <label>{props.label}</label>
      <div className="file-div">
        { filedata?.[props.name]
          ? <p>{props.label} has been selected.</p>
          : <input type='file' id={props.id} onChange={e => handleFileChosen(e.target.files[0])} />
        }
      </div>
    </div>
  }

  const InitState = (props) => {
    return <Form>
      <ConfirmItem id="userInitialMessage">{headernote}</ConfirmItem>
      {/* <ConfirmItem id="userInitialMessage2">{headernote2}</ConfirmItem> */}
      <ConfirmItem id="formCompleteNote"><b>ALL FIELDS MUST BE FILLED TO SUBMIT FORM</b></ConfirmItem>

      <h4>Applicant Information</h4>
      <br/>

      <FormItem id="applicantName" name="aname" label="Name" values={props.values} handleChange={props.handleChange} handleBlur={props.handleBlur} errors={props.errors} touched={props.touched}></FormItem>
      <FormSelectItem id="applicantRelationship" name="arel" label="Relationship" values={props.values} handleChange={props.handleChange} handleBlur={props.handleBlur} errors={props.errors} touched={props.touched}>
        <option disabled key={'DEFAULT'} value={'DEFAULT'}>Please select Relationship...</option>
        {rellist.map((item, key) =>
          <option key={key} value={item.description}>{item.description}</option>
        )}
      </FormSelectItem>
      { props.values.arel === "Other"
        ? <FormItem id="applicantRelationshipOther" name="arelo" label="Please describe relationship..." values={props.values} handleChange={props.handleChange} handleBlur={props.handleBlur} errors={props.errors} touched={props.touched}></FormItem>
        : null
      }
      <Form.Group>
        <ImportFromFileBodyComponent id="applicantRelationshipVeriDoc" name="arelfile" label="Relationship verifiction, if applicable (Notarized Letter/Court Order)" values={props.values} handleChange={props.handleChange} handleBlur={props.handleBlur} errors={props.errors} touched={props.touched}></ImportFromFileBodyComponent>
        <br/>
      </Form.Group>
      <FormItem id="applicantAddress1" name="aadd1" label="Mailing Address 1" values={props.values} handleChange={props.handleChange} handleBlur={props.handleBlur} errors={props.errors} touched={props.touched}></FormItem>
      <FormItem id="applicantAddress2" name="aadd2" label="Mailing Address 2" values={props.values} handleChange={props.handleChange} handleBlur={props.handleBlur} errors={props.errors} touched={props.touched}></FormItem>
      <FormItem id="applicantCity" name="acity" label="City" values={props.values} handleChange={props.handleChange} handleBlur={props.handleBlur} errors={props.errors} touched={props.touched}></FormItem>
      <FormSelectItem id="applicantState" name="astate" label="State" values={props.values} handleChange={props.handleChange} handleBlur={props.handleBlur} errors={props.errors} touched={props.touched}>
        {statelist.map((item, key) =>
          <option key={key} value={item.abbreviation}>{item.name}</option>
        )}
      </FormSelectItem>
      <FormItem id="applicantZip" name="azip" label="Zip Code" values={props.values} handleChange={props.handleChange} handleBlur={props.handleBlur} errors={props.errors} touched={props.touched}></FormItem>
      <FormItem id="applicantPhone" name="aphone" label="Phone" values={props.values} handleChange={props.handleChange} handleBlur={props.handleBlur} errors={props.errors} touched={props.touched}></FormItem>
      <FormItem id="applicantEmail" name="aemail" label="Email" values={props.values} handleChange={props.handleChange} handleBlur={props.handleBlur} errors={props.errors} touched={props.touched}></FormItem>

      <br/>
      <h4>ID Verification</h4>
      <br/>

      <ConfirmItem id="idVerificationNote">{idnote}</ConfirmItem>
      <FormSelectItem id="applicantIDType" name="idtype" label="ID Type" values={props.values} handleChange={props.handleChange} handleBlur={props.handleBlur} errors={props.errors} touched={props.touched}>
        <option disabled key={'DEFAULT'} value={'DEFAULT'}>Please select ID type, or specify NONE...</option>
        {idtypelist.map((item, key) =>
          <option key={key} value={item.oid}>{item.description}</option>
        )}
      </FormSelectItem>   
      {props.values.idtype !== "DEFAULT" ?
        props.values.idtype === "0"
        ? <>
          <ImportFromFileBodyComponent id="applicantVerificationDoc1" name="verifile1" label="Verification Document 1" values={props.values} handleChange={props.handleChange} handleBlur={props.handleBlur} errors={props.errors} touched={props.touched}></ImportFromFileBodyComponent>
          <br/>
          <ImportFromFileBodyComponent id="applicantVerificationDoc2" name="verifile2" label="Verification Document 2" values={props.values} handleChange={props.handleChange} handleBlur={props.handleBlur} errors={props.errors} touched={props.touched}></ImportFromFileBodyComponent>
        </>
        : <>
          <ImportFromFileBodyComponent id="applicantIDFile" name="idfile" label="Photo ID (Front)" values={props.values} handleChange={props.handleChange} handleBlur={props.handleBlur} errors={props.errors} touched={props.touched}></ImportFromFileBodyComponent>
          <br/>
          <FormCheckbox id="userAddressNotCurrent" name="addressflag" label={<p><b>Please check here if the address on the ID you have provided is not current.</b> You will be prompted to submit additional documents that verify your current address.</p>} values={props.values} handleChange={props.handleChange} handleBlur={props.handleBlur} errors={props.errors} touched={props.touched} />          
        </>
      : null}

      {props.values.addressflag !== 0
        ? <>
          <ImportFromFileBodyComponent id="applicantVerificationDoc1" name="verifile1" label="Verification Document 1" values={props.values} handleChange={props.handleChange} handleBlur={props.handleBlur} errors={props.errors} touched={props.touched}></ImportFromFileBodyComponent>
          <br/>
          <ImportFromFileBodyComponent id="applicantVerificationDoc2" name="verifile2" label="Verification Document 2" values={props.values} handleChange={props.handleChange} handleBlur={props.handleBlur} errors={props.errors} touched={props.touched}></ImportFromFileBodyComponent>
        </>
        : null
      }

      <br/>
      <h4>Certificate Information</h4>
      <br/>

      <FormItem id="certificateName" name="name" label="Name at Birth" values={props.values} handleChange={props.handleChange} handleBlur={props.handleBlur} errors={props.errors} touched={props.touched}></FormItem>
      <FormItem id="certificateOtherName" name="oname" label={othernamenote} values={props.values} handleChange={props.handleChange} handleBlur={props.handleBlur} errors={props.errors} touched={props.touched}></FormItem>
      <FormItem id="certificateBirthDate" name="bdate" label="Birth Date" values={props.values} handleChange={props.handleChange} handleBlur={props.handleBlur} errors={props.errors} touched={props.touched}></FormItem>
      <FormItem id="certificateAge" name="age" label="Current Age" values={props.values} handleChange={props.handleChange} handleBlur={props.handleBlur} errors={props.errors} touched={props.touched}></FormItem>
      <FormSelectItem id="certificateSex" name="bsex" label="Birth Sex" values={props.values} handleChange={props.handleChange} handleBlur={props.handleBlur} errors={props.errors} touched={props.touched}>
        <option disabled key={'DEFAULT'} value={'DEFAULT'}>Please select sex...</option>
        {sexlist.map((item, key) =>
          <option key={key} value={item.oid}>{item.description}</option>
        )}
      </FormSelectItem>   
      <FormSelectItem id="certificateBirthPlace" name="bplace" label="Place of Birth" values={props.values} handleChange={props.handleChange} handleBlur={props.handleBlur} errors={props.errors} touched={props.touched}>
        <option disabled key={'DEFAULT'} value={'DEFAULT'}>Please select birth place...</option>
        {countylist.map((item, key) =>
          <option key={key} value={item.oid}>{item.description}</option>
        )}
      </FormSelectItem>  
      <FormItem id="certificateHospital" name="hospital" label="Hospital" values={props.values} handleChange={props.handleChange} handleBlur={props.handleBlur} errors={props.errors} touched={props.touched}></FormItem>
      <FormItem id="certificateMotherMaidenName" name="mmname" label="Full Maiden Name of Mother" values={props.values} handleChange={props.handleChange} handleBlur={props.handleBlur} errors={props.errors} touched={props.touched}></FormItem>
      <FormItem id="certificateFatherName" disabled={props.values.fanameunknown} name="faname" label="Full Name of Father" values={props.values} handleChange={props.handleChange} handleBlur={props.handleBlur} errors={props.errors} touched={props.touched}>
        <Form.Check id="certificateFatherNameUnknown" name="fanameunknown" onChange={props.handleChange} onBlur={props.handleBlur} value={props.values.fanameunknown} label="Check here if father's name is unknown." />
      </FormItem>

      <br/>
      <FormItem id="electronicSignature" name="esig" label="Electronic Signature" values={props.values} handleChange={props.handleChange} handleBlur={props.handleBlur} errors={props.errors} touched={props.touched}></FormItem>
      <FormCheckbox id="userValidation" disable={props.values.esig === ''} name="validation" label="By typing my signature and checking this box, I assert that I am the person I represent myself to be herein, and I affirm that the information submitted on this form is complete and accurate and submitted subject to the criminal penalties set forth at Maryland Code Annotated, Health-General Section 4-227." values={props.values} handleChange={props.handleChange} handleBlur={props.handleBlur} errors={props.errors} touched={props.touched} />

      <br/>
      <h4>Payment Information</h4>
      <br/>

      <FormItem id="numCertificatesRequested" name="ncerts" label="Number Certs Requested" values={props.values} handleChange={props.handleChange} handleBlur={props.handleBlur} errors={props.errors} touched={props.touched}></FormItem>
      
      { props.values.idtype === "2"
        ? <>
          <ConfirmItem id="certificateTotalCostConfirmation">Total cost for certs requested: $0.00</ConfirmItem>
          <ConfirmItem id="nofeeNote">{feenote}</ConfirmItem>
        </>
        : <>
          <ConfirmItem id="certificateTotalCostConfirmation">Total cost for certs requested: ${props.pprops.cost*parseInt(props.values.ncerts ? props.values.ncerts : 0)}.00</ConfirmItem>
          <ConfirmItem id="paymentNote">Payment will not be processed until your application has been approved.</ConfirmItem>
          <FormItem id="paymentCardNumber" name="cnumb" label="Card Number" values={props.values} handleChange={props.handleChange} handleBlur={props.handleBlur} errors={props.errors} touched={props.touched}></FormItem>
          <FormItem id="paymentCardholderName" name="chname" label="Cardholder Name" values={props.values} handleChange={props.handleChange} handleBlur={props.handleBlur} errors={props.errors} touched={props.touched}></FormItem>
          <FormItem id="paymentSecCode" name="csec" label="Security Code" values={props.values} handleChange={props.handleChange} handleBlur={props.handleBlur} errors={props.errors} touched={props.touched}></FormItem>
          <FormItem id="paymentExpMonth" name="cexpm" label="Expiration Month" values={props.values} handleChange={props.handleChange} handleBlur={props.handleBlur} errors={props.errors} touched={props.touched}></FormItem>
          <FormItem id="paymentExpYear" name="cexpy" label="Expiration Year" values={props.values} handleChange={props.handleChange} handleBlur={props.handleBlur} errors={props.errors} touched={props.touched}></FormItem>
          <FormCheckbox id="userBillingDifferent" name="badifferent" label="My billing address is different from my mailing address" values={props.values} handleChange={props.handleChange} handleBlur={props.handleBlur} errors={props.errors} touched={props.touched} />
          { props.values.badifferent
            ? <>
                <FormItem id="billingAddress1" name="badd1" label="Billing Address 1" values={props.values} handleChange={props.handleChange} handleBlur={props.handleBlur} errors={props.errors} touched={props.touched}></FormItem>
                <FormItem id="billingAddress2" name="badd2" label="Billing Address 2" values={props.values} handleChange={props.handleChange} handleBlur={props.handleBlur} errors={props.errors} touched={props.touched}></FormItem>
                <FormItem id="billingCity" name="bcity" label="Billing City" values={props.values} handleChange={props.handleChange} handleBlur={props.handleBlur} errors={props.errors} touched={props.touched}></FormItem>
                <FormSelectItem id="billingState" name="bstate" label="Billing State" values={props.values} handleChange={props.handleChange} handleBlur={props.handleBlur} errors={props.errors} touched={props.touched}>
                  {statelist.map((item, key) =>
                    <option key={key} value={item.abbreviation}>{item.name}</option>
                  )}
                </FormSelectItem>
                <FormItem id="billingZipCode" name="bzip" label="Billing Zip Code" values={props.values} handleChange={props.handleChange} handleBlur={props.handleBlur} errors={props.errors} touched={props.touched}></FormItem>
              </>
            : null
          }
        </>
      }
      { ((props.values?.arel === "Other" && !props.values?.arelo) || !props.values?.validation || !(props.isValid && props.dirty) || ((props.values?.idtype !== "2") && (!props.values?.cnumb || !props.values?.csec || !props.values?.cexpm || !props.values?.cexpy)) || ((props.values?.badifferent) && (!props.values?.badd1 || !props.values?.bcity || !props.values?.bzip)))
        ? <ConfirmItem id="notCompleteMessage"><b>You are missing one or more fields.</b> If you believe that you have completed the form and are still receiving this message, <div style={{cursor: 'pointer', color: 'blue', textDecoration: 'underline', display: 'inline-block'}} href={0} onClick={() => submitBug(props.values)}>click here to submit a partial request.</div></ConfirmItem>
        : null
      }
      <Button variant="primary" onClick={() => submitData(props.values)} disabled={(props.values?.arel === "Other" && !props.values?.arelo) || !props.values?.validation || !(props.isValid && props.dirty) || ((props.values?.idtype !== "2") && (!props.values?.cnumb || !props.values?.csec || !props.values?.cexpm || !props.values?.cexpy)) || ((props.values?.badifferent) && (!props.values?.badd1 || !props.values?.bcity || !props.values?.bzip))}>Submit</Button>
      <br/>
    </Form>; 
  }

  // We have received your request for a Birth Certificate. In order to continue processing your application, the applicant must come into the office. If you have any questions, or did not make this request, please call {props.pprops.contactphone} and reference your order #{confirmationID}.

  const ConfirmState = (props) => {
    return <p>We have received your request for a Birth Certificate. In order to continue processing your application, the applicant must come into the office. Birth certificates are available for pickup Monday, Wednesday, and Friday only from 8:30-4:00 (closed for lunch from 1-1:30). If you have any questions, or did not make this request, please call {props.pprops.contactphone} and reference your order #{confirmationID}.</p>;
  }

  const BugConfirmState = (props) => {
    return <p>We have received your request for a Birth Certificate. In order to continue processing your application, the applicant must come into the office. Birth certificates are available for pickup Monday, Wednesday, and Friday only from 8:30-4:00 (closed for lunch from 1-1:30). If you have any questions, or did not make this request, please call {props.pprops.contactphone} and reference your order #{confirmationID}.</p>;
  }

  const FailState = (props) => {
    return <p>An error has occurred. Please try again, or contact the health department at {props.pprops.contactphone}.</p>;
  }

  const PendingState = (props) => {
    return <p>Processing your request. Please do not close this page...</p>;
  }

  const renderSwitch = (state, values, errors, touched, handleChange, handleBlur, isValid, dirty, props) => { 
    switch(state) {
      case 'init':
        return <InitState values={values} errors={errors} touched={touched} handleChange={handleChange} handleBlur={handleBlur} isValid={isValid} dirty={dirty} pprops={props} />;
        case 'confirm':
          return <ConfirmState values={values} errors={errors} touched={touched} handleChange={handleChange} handleBlur={handleBlur} isValid={isValid} dirty={dirty} pprops={props} />;
        case 'bug-confirm':
          return <BugConfirmState values={values} errors={errors} touched={touched} handleChange={handleChange} handleBlur={handleBlur} isValid={isValid} dirty={dirty} pprops={props} />;
        case 'pending':
          return <PendingState values={values} errors={errors} touched={touched} handleChange={handleChange} handleBlur={handleBlur} isValid={isValid} dirty={dirty} pprops={props} />;
      case 'fail':
        return <FailState values={values} errors={errors} touched={touched} handleChange={handleChange} handleBlur={handleBlur} isValid={isValid} dirty={dirty} pprops={props} />;
      default:
        return null;
    }
  }

  return (
    <div className="content-div">
      <div className="image-div">
        <img alt="" src="public-health-shield-half.png" style={{display: "block", float: "left"}} />
      </div>
      <div className="title-div">
        <h2>Birth Certificate Application</h2>
        <h4>{props.agencyName}</h4>
      </div>
      <br/>
      <Formik // select: 'DEFAULT', state: 'MD', num: 0, string: '', bool: false, date: '',
        initialValues={{ addressflag: 0, astate: 'MD', idtype: 'DEFAULT', bsex: 'DEFAULT', bplace: 'DEFAULT', arel: 'DEFAULT', bstate: 'MD', esig: '', aname: '', arelo: '', aadd1: '', aadd2: '', acity: '', azip: '', aphone: '', aemail: '', verifile1: '', verifile2: '', arelfile: '', idfile: '', name: '', oname: '', bdate: '', age: '', hospital: '', mmname: '', faname: '', ncerts: 0, cnumb: '', csec: '', cexpm: '', cexpy: '', badd1: '', badd2: '', bcity: '', bzip: '', fanameunknown: false, validation: false, badifferent: false }}
        validationSchema={validationSchema}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          isValid,
          dirty
          /* and other goodies */
        }) => renderSwitch(state, values, errors, touched, handleChange, handleBlur, isValid, dirty, props) }
      </Formik>
    </div>
  );
}

export { MainContent };
