const sexlist = [
    {
        'oid': 0,
        'description': 'Unknown'
    },
    {
        'oid': 1,
        'description': 'Male'
    },
    {
        'oid': 2,
        'description': 'Female'
    }
]

const idtypelist = [
    {
        'oid': 0,
        'description': 'None'
    },
    {
        'oid': 1,
        'description': 'Drivers License'
    },
    {
        'oid': 2,
        'description': 'Military Identification'
    },
    {
        'oid': 3,
        'description': 'Government Issued'
    }
]

const countylist = [
    {
        'oid': 0,
        'description': 'Unknown'
    },
    {
        'oid': 1,
        'description': 'Allegany County'
    },
    {
        'oid': 2,
        'description': 'Anne Arundel County'
    },
    {
        'oid': 3,
        'description': 'Baltimore City'
    },
    {
        'oid': 4,
        'description': 'Baltimore County'
    },
    {
        'oid': 5,
        'description': 'Calvert County'
    },
    {
        'oid': 6,
        'description': 'Caroline County'
    },
    {
        'oid': 7,
        'description': 'Carroll County'
    },
    {
        'oid': 8,
        'description': 'Cecil County'
    },
    {
        'oid': 9,
        'description': 'Charles County'
    },
    {
        'oid': 10,
        'description': 'Dorchester County'
    },
    {
        'oid': 11,
        'description': 'Frederick County'
    },
    {
        'oid': 12,
        'description': 'Garrett County'
    },
    {
        'oid': 13,
        'description': 'Harford County'
    },
    {
        'oid': 14,
        'description': 'Howard County'
    },
    {
        'oid': 15,
        'description': 'Kent County'
    },
    {
        'oid': 16,
        'description': 'Montgomery County'
    },
    {
        'oid': 17,
        'description': 'Prince Georges County'
    },
    {
        'oid': 18,
        'description': 'Queen Annes County'
    },
    {
        'oid': 19,
        'description': 'Somerset County'
    },
    {
        'oid': 20,
        'description': 'St Marys County'
    },
    {
        'oid': 21,
        'description': 'Talbot County'
    },
    {
        'oid': 22,
        'description': 'Washington County'
    },
    {
        'oid': 23,
        'description': 'Wicomico County'
    },
    {
        'oid': 24,
        'description': 'Worcester County'
    }
]

const rellist = [
    {
        'description': 'Mother'
    },
    {
        'description': 'Father'
    },
    {
        'description': 'Self'
    },
    {
        'description': 'Other'
    },
]

export { sexlist, idtypelist, countylist, rellist }