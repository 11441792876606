import React, { Component } from 'react';
import './App.css';
import { sendPostRequest } from './components/api';
import { ReCaptcha, loadReCaptcha } from 'react-recaptcha-v3'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { MainContent } from './components/MainContent'
import { clientkey } from './components/clientkey'

class MyReCaptcha extends Component {
  verifyCallback = (recaptchaToken) => {
    sendPostRequest('verify', {
      'clientkey': clientkey,
      'response': recaptchaToken
    })
  }

  updateToken = () => {
    this.recaptcha.execute();
  }

  render() {
    return (
      <ReCaptcha 
        ref={ref => this.recaptcha = ref}
        sitekey="6Lelfr4ZAAAAANp_Kq5u_N3fq92Z6N19-5wrZY9m"
        action='verify_activity'
        verifyCallback={this.verifyCallback}
      />
    );
  }
}

const Block404 = () => {
  return (
    <div className="content-div">
      <p>Something went wrong! This page does not exist yet.</p>
    </div>
  )
}

class App extends Component {

  componentDidMount() {
    loadReCaptcha('6Lelfr4ZAAAAANp_Kq5u_N3fq92Z6N19-5wrZY9m');
  }

  render() {
    return (
      <>
        <Router>
          <Routes>
            <Route path="/somerset" element={<MainContent agency="SOMERSET" agencyName="Somerset County Health Department" contactphone="(443) 523-1700" cost={25} />} />
            <Route path="/wicomico" element={<MainContent agency="WICOMICO" agencyName="Wicomico County Health Department" contactphone="(410) 543-6949" cost={22} />} />
            {/* <Route path="/cecil" element={<MainContent agency="CECIL" agencyName="Cecil County Health Department" contactphone="(410) 996-5550" cost={25} />} /> */}
            <Route path="/mag" element={<MainContent agency="MAG" agencyName="Development Page" contactphone="(XXX) XXX-XXXX" cost={22} />} />
            <Route path="/" element={<Block404 />} />
          </Routes>
        </Router>
        <MyReCaptcha />
      </>
    );
  }

}

export default App;
