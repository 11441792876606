import * as Yup from 'yup'

const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/

const validationSchema = Yup.object().shape({
  aname: Yup.string()
    .min(2, "Applicant Name must be longer than 2 characters")
    .max(100, "Applicant Name cannot be longer than 100 characters")
    .required("Applicant Name is required"),
  arelo: Yup.string()
    .min(2, "Applicant Relationship must be longer than 2 characters")
    .max(100, "Applicant Relationship cannot be longer than 100 characters"),
  arel: Yup.string()
    .test('non-default', 'Applicant Relationship selection is required', val => val !== 'DEFAULT'),
  aadd1: Yup.string()
    .min(2, "Applicant Address 1 must be longer than 2 characters")
    .max(100, "Applicant Address 1 cannot be longer than 100 characters")
    .required("Applicant Address 1 is required"),
  aadd2: Yup.string()
    .min(2, "Applicant Address 2 must be longer than 2 characters")
    .max(100, "Applicant Address 2 cannot be longer than 100 characters"),
  acity: Yup.string()
    .min(2, "Applicant City must be longer than 2 characters")
    .max(100, "Applicant City cannot be longer than 100 characters")
    .required("Applicant City is required"),
  astate: Yup.string()
    .test('non-default', 'Applicant State selection is required', val => val !== 'DEFAULT'),
  azip: Yup.string()
    .min(2, "Applicant Zip Code must be longer than 2 characters")
    .max(100, "Applicant Zip Code cannot be longer than 100 characters")
    .required("Applicant Zip Code is required"),
  aemail: Yup.string()
    .email("Must be a valid email address")
    .max(100, "Applicant Email cannot be longer than 100 characters")
    .required("Applicant Email is required"),
  aphone: Yup.string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("Applicant Phone is required"),
  idtype: Yup.string()
    .test('non-default', 'ID Type selection is required', val => val !== 'DEFAULT'),
  name: Yup.string()
    .min(2, "Name at Birth must be longer than 2 characters")
    .max(100, "Name at Birth cannot be longer than 100 characters")
    .required("Name at Birth is required"),
  oname: Yup.string()
    .min(2, "Other Name must be longer than 2 characters")
    .max(100, "Other Name cannot be longer than 100 characters"),
  bdate: Yup.date()
    .required("Birth Date is required")
    .min(new Date("1/1/1800"), "Birth Date out of range")
    .max(new Date("12/31/2030"), "Birth Date out of range"),
  age: Yup.number()
    .required("Current Age is required"),
  bsex: Yup.string()
    .test('non-default', 'Birth Sex selection is required', val => val !== 'DEFAULT'),
  bplace: Yup.string()
    .test('non-default', 'Place of Birth selection is required', val => val !== 'DEFAULT'),
  hospital: Yup.string()
    .min(2, "Hospital must be longer than 2 characters")
    .max(100, "Hospital cannot be longer than 100 characters")
    .required("Hospital is required"),
  mmname: Yup.string()
    .min(2, "Mother Maiden Name must be longer than 2 characters")
    .max(100, "Mother Maiden Name cannot be longer than 100 characters")
    .required("Mother Maiden Name is required"),
  faname: Yup.string()
    .min(2, "Father Name must be longer than 2 characters")
    .max(100, "Father Name cannot be longer than 100 characters"),
  esig: Yup.string()
    .min(2, "Electronic Signature must be longer than 2 characters")
    .max(100, "Electronic Signature cannot be longer than 100 characters")
    .required("Electronic Signature is required"),
  ncerts: Yup.number()
    .required("Number Certs Requested is required"),
  cnumb: Yup.number(),
  csec: Yup.number()
    .min(0, "Security Code must be valid")
    .max(9999, "Security Code must be valid"),
  cexpm: Yup.number()
    .min(1, "Expiration Month must be valid")
    .max(12, "Expiration Month must be valid"),
  cexpy: Yup.number()
    .min(0, "Expiration Year must be valid")
    .max(9999, "Expiration Year must be valid"),
  badd1: Yup.string()
    .min(2, "Billing Address 1 must be longer than 2 characters")
    .max(100, "Billing Address 1 cannot be longer than 100 characters"),
  badd2: Yup.string()
    .min(2, "Billing Address 2 must be longer than 2 characters")
    .max(100, "Billing Address 2 cannot be longer than 100 characters"),
  bcity: Yup.string()
    .min(2, "Billing City must be longer than 2 characters")
    .max(100, "Billing City cannot be longer than 100 characters"),
  bzip: Yup.string()
    .min(2, "Billing Zip Code must be longer than 2 characters")
    .max(100, "Billing Zip Code cannot be longer than 100 characters")
});

export { validationSchema }